import React from "react";

export default function Footer(props) {
  return (
    <footer
      className="fixed-bottom"
      style={{ color: "white", fontSize: "0.65rem" }}
    >
      <div>
        {props.language === "zh" ? "最后更新: " : "Last Update: "}13/08/20 
      </div>
      <div>
        <img
          className="heart"
          src={process.env.PUBLIC_URL + "/icons/lightning.svg"}
          alt="lightning"
        />{" "}
        <span style={{ color: "white", fontSize: "0.65rem" }}>al</span>
      </div>
    </footer>
  );
}
