import React from 'react'
import '../stylesheets/App.css';

export default function About(props) {
  let language = props.lang
  return (<div style={{textAlign: 'left', color: 'white', padding: '2rem' }}>
    <h1 style={{fontSize: '1.8rem', marginTop: '2.3rem'}}>{language === 'en' ? "Is this reliable?" : "這裡 DATA 可靠嗎?"}</h1>
    <p style={{fontSize: '.85rem'}}>{language === 'en' ? "The data used on this site is provided by the Department of Health, updated nightly. An automated job first acquires the new data from the official HK API platform, DATA.GOV.HK, then processes it through Google's geocoding service to generate coordinate data. The timestamp on the bottom left corner indicates the last time the data here was updated. The coordinates are fairly accurate, but there are exceptional cases where the coordinates are incorrect. This could be due to arbitrary location names, multiple locations sharing names, locations with multiple names, or inaccuracy in Google's geocoding service. I put in effort to remedy these kinds of cases, but I may miss some. If you do notice inaccuracy (i.e. an incorrectly marked building or estate) please e-mail me at info@hksurvival.com." : "本網站上使用的數據由衛生部提供，每晚更新一次。自動化作業首先從官方的香港API平台DATA.GOV.HK獲取新數據，然後通過Google的地理編碼服務對其進行處理以生成坐標數據。左下角的時間戳指示上次更新數據的時間。坐標相當準確，但是在某些特殊情況下，坐標不正確。這可能是由於任意位置名稱，多個共享名稱的位置，多個名稱的位置或Google的地理編碼服務不准確所致。我已盡力糾正這種情況，但我可能會錯過一些情況。如果您確實發現不正確的地方（即建築物或房地產標記錯誤），請發送電子郵件至 info@hksurvival.com."}</p>

 {language === "en" ? "Data Sources:" : "數據源:"} <a href="https://data.gov.hk/en-data/dataset/hk-dh-chpsebcddr-novel-infectious-agent" rel="noopener noreferrer" target="_blank">data.gov.hk</a>
    </div>)
}


