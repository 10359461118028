import React, {useState} from 'react';
import Map from '../modules/Map'
import Cases from '../modules/Cases'
import Cases_copy from '../modules/Cases'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import allBuildings from '../data/buildings.geojson'
import allCases from '../data/cases.json'
import mtrStations from '../data/mtr_coordinates.geojson'

function App(props) {

  const [selectedBuilding, setSelectedBuilding] = useState(null)

  let language = props.lang

  return (
    <div>
      <div className="top-wrapper">
        <Row noGutters={true} className="map-full-size">
            <Map 
            allBuildings={allBuildings}
            selectedBuilding={selectedBuilding} 
            lang = {language}
            setSelectedBuilding={setSelectedBuilding}
            mtrStations = {mtrStations}
            />
       </Row>
       <div className="legend">
           <div className="legend-item">
           <img className="heart" src={process.env.PUBLIC_URL + '/icons/bhazard.svg'} alt="danger"/> <span style={{"color" : "white", "fontSize": "0.65rem"}}>{language === "en" ? "Infected Area (Last 14 Days)" : "感染區 (過去14天)"}</span></div>
           <div className="legend-item">
           <img className="mtr" src={process.env.PUBLIC_URL + '/icons/mtr.png'} alt="danger"/> <span style={{"color" : "white", "fontSize": "0.65rem"}}>{language === "en" ? "MTR Station" : "港鐵站"}</span></div>
       </div>
       </div>
       <div className="bottom-wrapper">
         
       <Container fluid>
         <Cases
         allCases = {allCases}
         lang = {language}
         selectedBuilding={selectedBuilding ? selectedBuilding.features[0].properties : null}/>
       </Container>
       </div>
    </div>
  );
}


export default App;
