import React, { useState } from "react";
import { useRoutes } from "hookrouter";
import "./stylesheets/App.css";
import About from "./hl-components/About";
import SideMenu from "./modules/SideMenu";
import App from "./hl-components/App";
import Header from "./fixed-components/Header"
import Footer from "./fixed-components/Footer"
import Viz from "./hl-components/Viz"

export default function RoutedApp() {
  let [language, setLanguage] = useState("zh");
  let [menu, setMenu] = useState(false);
  let changeLang = () => {
    setLanguage(language === "en" ? "zh" : "en");
  };
  let checkMenu = (state) => {
    setMenu(state.isOpen);
  };

  return (
    <div className="App">
      <SideMenu lang={language} isOpen={menu} checkMenu={checkMenu}></SideMenu>
      <Header changeLang={changeLang} setMenu={setMenu} menu={menu} language={language} />
      <Routes lang={language} changeLang={changeLang} />
      <Footer language={language} />
    </div>
  );
}

function Routes(props) {
  const routes = {
    "/": () => <App lang={props.lang}/>,
    "/about": () => <About lang={props.lang}/>,
    "/stats": () => <Viz lang={props.lang}/>
  };
  const routeResult = useRoutes(routes);
  return routeResult;
}
