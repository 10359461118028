import React, {useState} from "react";
import Row from "react-bootstrap/Row";
import Modal from 'react-bootstrap/Modal'
import Card from "react-bootstrap/Card"

export default function Cases(props) {

  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState({})
  const handleShow = () => setShowModal(true)
  const handleClose = () => setShowModal(false) 

  return (
    <div>
      <Modal
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide = {handleClose}
      show = {showModal}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h6>{props.lang === "en" ? "Case" : "病例"} #{data.id}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
        {props.lang === "en" ? data.summary : data.summary_chi}
       </p>
      </Modal.Body>
    </Modal>
      {props.selectedBuilding
        ? props.selectedBuilding.case.map((c, index, arr) => {
            let i = parseInt(c) - 1;
            if (!parseInt(c)) {
              return null;
            } else {
              return (
                  <Row className="justify-content-center" key={index}>
                  <Card onClick={() => {props.allCases[i].summary != null ? setShowModal(true) : setShowModal(false); setData(props.allCases[i])}}
                    bg={
                      props.allCases
                        ? props.allCases[i]
                          ? props.allCases[i].status === "Discharged"
                            ? "success"
                            : "dark"
                          : "dark"
                        : "dark"
                    }
                    text={"white"}
                    key={props.selectedBuilding.name}
                    className={arr.length - 1 === index ? "bottom-card" : null}
                  >
                    <Card.Body>
                      <Card.Title>
                        {props.lang === "en" ? "Case" : "病例"} #
                        {props.allCases[i].id}:{" "}
                        {props.lang === "en"
                          ? props.allCases[i].gender === "M"
                            ? "Male"
                            : "Female"
                          : props.allCases[i].gender === "M"
                            ? "男"
                            : "女"
                          } 
                        , {props.allCases[i].age}{" "}
                        {props.lang === "en" ? null : "歲"}
                      </Card.Title>
                      <Card.Subtitle>
                        {props.allCases[i].onsetdate === "Asymptomatic"
                          ? null
                          : props.lang === "en"
                          ? "Onset Date: "
                          : "發病日期: "}
                        {props.allCases[i].onsetdate}
                      </Card.Subtitle>
                      <Card.Subtitle>
                        {
                        props.allCases[i].status === null ? 
                        "" : 
                        props.lang === "en" ?
                        props.allCases[i].status === "Pending admission"
                          ? "Pending"
                          : props.allCases[i].status 
                          : props.allCases[i].status_chi 
                          }{" "}
                        {props.allCases[i].hospital === "Pending"
                          ? null
                          : 
                          props.lang === "en" ?
                          props.allCases[i].hospital :
                          props.allCases[i].hospital_chi
                          }
                      </Card.Subtitle>
                      <Card.Text>{props.lang === "en" ? props.allCases[i].residence : props.allCases[i].residence_chi }</Card.Text>
                    </Card.Body>
                  </Card>
                </Row>
              );
            }
          })
        : null}
    </div>
  );
}
