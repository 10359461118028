import React from "react";

export default function Header(props) {
  return (
    <header className="fixed-top">
      <button
        style={{
          background: "transparent",
          border: "none",
          outline: "none",
          width: "1.962rem",
        }}
        onClick={() => {
          props.setMenu(!props.menu);
        }}
      >
        <img src={process.env.PUBLIC_URL + "/icons/ham.svg"} alt="menu" />
      </button>
      {props.language === "en" ? "COVID-19 Hong Kong" : "COVID-19 香港"}
      <button onClick={props.changeLang} className="lang btn-sm btn-dark">
        {props.language === "zh" ? "En" : "中"}
      </button>
    </header>
  );
}
