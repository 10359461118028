import React from 'react'
import '../stylesheets/App.css';
import allCases from '../data/cases.json'
import stats from '../data/stats.json'
import dayStats from '../data/day_stats.json'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import '../stylesheets/style.css';
import { XYPlot, LineSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis } from 'react-vis';

export default function Viz(props) {

    const data = stats.map((item) => { return { x: new Date(item.x), y: item.y } })

    return (
        <div style={{ color: 'white', padding: '2rem', marginTop: '2.3rem' }}>
            <Row className="justify-content-center">
                <XYPlot animation={true} xType="time" height={300} width={300}>
                    <LineSeries style={{ strokeWidth: 3 }} data={data} />
                    <XAxis />
                    <YAxis />
                </XYPlot>
            </Row>
            <Row className="justify-content-center">
                <h4>{props.lang === "en" ? "Total Cases" : "確診"}: <span style={{ color: 'red' }}>{allCases.length}</span></h4>
            </Row>
            <Row className="justify-content-center">
                <h6>{dayStats[0]}: <span style={{ color: 'red' }}>{dayStats[1]}</span></h6>
            </Row>
            <Row className="justify-content-center">
                <h6>{dayStats[2]}: <span style={{ color: 'red' }}>{dayStats[3]}</span></h6>
            </Row>
        </div>
    )
}