import React from "react";
import { slide as Menu } from "react-burger-menu";

export default function SideMenu(props) {
  return (
    <Menu
      width="10.526rem"
      customBurgerIcon={false}
      isOpen={props.isOpen}
      onStateChange={props.checkMenu}
    >
      <div className="menu-item">
        <img
          src={process.env.PUBLIC_URL + "/icons/bhazard.svg"}
          alt="logo"
          style={{ width: "1.579rem", height: "1.579rem" }}
        />
      </div>
      <a id="home" className="menu-item" href="/">
        {props.lang === "en" ? "Map" : "地圖"}
      </a>
      <a id="viz" className="menu-item" href="/stats">
        {props.lang === "en" ? "Stats" : "數據"}
      </a>
      <a id="about" className="menu-item" href="/about">
        {props.lang === "en" ? "About" : "可靠嗎?"}
      </a>
      
    </Menu>
  );
}
