import React, { PureComponent } from "react";

const pinStyle = {
  cursor: "pointer",
  fill: "#f2fff8",
  stroke: "white",
  strokeWidth: 3,
  overflow: "visible",
};

export default class PointerHelp extends PureComponent {
  render() {
    const { size = 20, onClick } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={size}
        viewBox="0 0 24 24"
        style={{
          ...pinStyle,
          transform: `translate(${-size / 2}px,${-size}px)`,
        }}
        onClick={onClick}
        className="moving-object"
      >
        <path d="M 32 15 L 32 21 C 32 21.191406 31.972656 21.386719 31.921875 21.574219 L 29.640625 30.074219 C 29.332031 31.203125 28.183594 32 26.859375 32 L 12 32 C 11.082031 32 10.230469 31.621094 9.6875 30.96875 L 0.546875 19.96875 C -0.378906 18.855469 -0.0976562 17.292969 1.175781 16.480469 C 2.453125 15.667969 4.238281 15.910156 5.167969 17.03125 L 7.425781 19.75 L 7.425781 2.5 C 7.425781 1.121094 8.707031 0 10.285156 0 C 11.863281 0 13.140625 1.121094 13.140625 2.5 L 13.140625 15 L 13.714844 15 L 13.714844 12.5 C 13.714844 11.121094 14.996094 10 16.574219 10 C 18.152344 10 19.425781 11.121094 19.425781 12.5 L 19.425781 15 L 20 15 L 20 13.5 C 20 12.121094 21.28125 11 22.859375 11 C 24.4375 11 25.714844 12.121094 25.714844 13.5 L 25.714844 15 L 26.285156 15 C 26.285156 13.621094 27.5625 12.5 29.140625 12.5 C 30.71875 12.5 32 13.621094 32 15 Z M 13.714844 20 L 13.140625 20 L 13.140625 26 L 13.714844 26 Z M 20 20 L 19.425781 20 L 19.425781 26 L 20 26 Z M 26.285156 20 L 25.714844 20 L 25.714844 26 L 26.285156 26 Z M 26.285156 20 " />
      </svg>
    );
  }
}
