import React, { useRef, useState } from "react";
import MapGL, {
  Source,
  Layer,
  Popup,
  Marker,
  GeolocateControl,
} from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import bhazard from "../assets/bhazard.png";
import mtr from "../assets/mtr.png";
import Pin from "./Pin";
import PointerHelp from "./PointerHelp";

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const searchLimit = {
  maxBounds: {
    minLongitude: 113.755014,
    minLatitude: 22.124708,
    maxLongitude: 114.436914,
    maxLatitude: 22.554077,
  },
};

const limit = {
  maxBounds: {
    minLongitude: 112,
    minLatitude: 21,
    maxLongitude: 115,
    maxLatitude: 23,
  },
};

const geolocateStyle = {
  position: "absolute",
  top: 0,
  right: 0,
  margin: "0.562rem 0.562rem 0 0",
};

export default function Map(props) {
  const [viewport, setViewPort] = useState({
    latitude: 22.295,
    longitude: 114.168,
    width: "100vw",
    height: "21rem",
    zoom: 15.4,
  });

  const [searchResultMarker, setSearchResultMarker] = useState(null);
  const [helpStartmarker, setHelpStartMarker] = useState(false);

  let _onLoad = () => {
    if (!mapRef) return;
    const map = mapRef.current.getMap();
    map.loadImage(bhazard, (e, i) => {
      if (e) {
        return;
      } else {
        map.addImage("bHazard", i);
      }
    });
    map.loadImage(mtr, (e, i) => {
      if (e) {
        return;
      } else {
        map.addImage("mtr", i);
      }
    });
    setHelpStartMarker(true);
  };

  const dataLayer = {
    id: "buildings",
    type: "symbol",
    layout: {
      "icon-image": "bHazard",
      "icon-size": 1,
      "icon-allow-overlap": true,
    },
  };

  const mtrLayer = {
    id: "mtr",
    type: "symbol",
    layout: { "icon-image": "mtr", "icon-size": 1 },
  };

  const mapRef = useRef();
  const geoRef = useRef();

  let _onClick = (building) => {
    if (building.features.length !== 0) {
      if (helpStartmarker) {
        setHelpStartMarker(false);
      }
      let b = building;
      b.features[0].properties.case = JSON.parse(b.features[0].properties.case);
      props.setSelectedBuilding(b);
    }
  };

  let _onViewportChange = (viewport) => {
    if (viewport.longitude < limit.maxBounds.minLongitude) {
      viewport.longitude = limit.maxBounds.minLongitude;
    } else if (viewport.longitude > limit.maxBounds.maxLongitude) {
      viewport.longitude = limit.maxBounds.maxLongitude;
    } else if (viewport.latitude < limit.maxBounds.minLatitude) {
      viewport.latitude = limit.maxBounds.minLatitude;
    } else if (viewport.latitude > limit.maxBounds.maxLatitude) {
      viewport.latitude = limit.maxBounds.maxLatitude;
    }
    setViewPort(viewport);
  };

  return (
    <div>
      <div
        ref={geoRef}
        style={{
          background: "transparent",
          display: "flex",
          alignItems: "center",
          paddingLeft: 4,
          paddingRight: 4,
          position: "absolute",
          margin: "0.562rem 0 0 0.562rem",
          width: "85vw",
        }}
      />

      <MapGL
        ref={mapRef}
        {...viewport}
        maxZoom={18}
        minZoom={9}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        mapStyle="mapbox://styles/acklackl/ck8q3fkbv18md1imvlc4y8164?optimize=true"
        onViewportChange={(viewport) => _onViewportChange(viewport)}
        onLoad={_onLoad}
        interactiveLayerIds={["buildings"]}
        onClick={_onClick}
        width="100vw"
        height="21rem"
        preventStyleDiffing={true}
        onInteractionStateChange={(action) => {
          if (action.isZooming) {
            setHelpStartMarker(false);
          }
        }}
      >
        {searchResultMarker ? (
          <Marker
            latitude={searchResultMarker[1]}
            longitude={searchResultMarker[0]}
          >
            <Pin />
          </Marker>
        ) : null}

        {helpStartmarker ? (
          <div>
            <Marker latitude={22.2943} longitude={114.1689}>
              <PointerHelp />
            </Marker>
            <Marker latitude={22.2952} longitude={114.1688}>
              <span className="pulse" />
            </Marker>
          </div>
        ) : null}

        {props.selectedBuilding ? (
          <Marker
            latitude={props.selectedBuilding.lngLat[1]}
            longitude={props.selectedBuilding.lngLat[0]}
          >
            <span className="pulse-finite" />
          </Marker>
        ) : null}

        <Source type="geojson" data={props.allBuildings} tolerance={3.5}>
           <Layer {...dataLayer} />
        </Source>

        <Source type="geojson" data={props.mtrStations} tolerance={3.5}>
          <Layer {...mtrLayer} />
        </Source>

        {props.selectedBuilding ? (
          <Popup
            latitude={props.selectedBuilding.lngLat[1]}
            longitude={props.selectedBuilding.lngLat[0]}
            onClose={() => {
              props.setSelectedBuilding(null);
            }}
            tipSize={0}
            closeButton={false}
            anchor="bottom"
          >
            <div>
              <b>
                {
                props.lang === "en" ?
                   props.selectedBuilding.features[0].properties.subdistrict == 'null' ?
                   
                   props.selectedBuilding.features[0].properties.district : 
                   props.selectedBuilding.features[0].properties.subdistrict
                  : 
                  
                 props.selectedBuilding.features[0].properties.subdistrict === 'null' ?
                  
                  props.selectedBuilding.features[0].properties.district_chi :
                  props.selectedBuilding.features[0].properties.subdistrict_chi 

                }
              </b>
              <br />
              {props.lang === "en"
                ? props.selectedBuilding.features[0].properties.name
                : props.selectedBuilding.features[0].properties.name_chi}
            </div>
          </Popup>
        ) : null}
        <GeolocateControl
          positionOptions={{ enableHighAccuracy: true }}
          trackUserLocation={true}
          style={geolocateStyle}
        />
      </MapGL>
      <Geocoder
        containerRef={geoRef}
        mapRef={mapRef}
        accessToken={MAPBOX_TOKEN}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        position="top-left"
        onViewportChange={(viewport) => {
          setViewPort(viewport);
        }}
        bbox={[
          searchLimit.maxBounds.minLongitude,
          searchLimit.maxBounds.minLatitude,
          searchLimit.maxBounds.maxLongitude,
          searchLimit.maxBounds.maxLatitude,
        ]}
        collapsed={false}
        language={props.lang === "en" ? "en" : "zh-Hant"}
        placeholder={props.lang === "en" ? "Search" : "搜尋 (地點)"}
        onResult={(result) => {
          setSearchResultMarker(result.result.geometry.coordinates);
          setHelpStartMarker(false);
        }}
        onClear={() => {
          setSearchResultMarker(null);
        }}
        enableEventLogging={false}
      />
    </div>
  );
}
